import React from "react";
import { withPrefix } from "gatsby";
import { newRelicWrapper } from "@shared/utils/Errors";
import { windowExists, getQueryParams } from "@shared/utils/WebBrowser";

// this is mostly here to help developers.  we want to log if users ever get here, because they should not
// eventually we could turn this into a simplified dashboard if there are no query params, so that devs can choose their product
// from a simplified list (standard BC, premium finish BC, embroidery, maybe a few others)
export default function IndexPage() {
    if (windowExists()) {
        newRelicWrapper.logPageAction("studio-index-page");
        if (getQueryParams().raso) {
            return <div>You should be signed OUT and redirected to your original page</div>;
        }
        window.location.href = withPrefix(`/studio/five/en-ie/${window.location.search}`);
    }

    return <div></div>;
}

IndexPage.displayName = "IndexPage";
